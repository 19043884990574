/* eslint-disable max-len */
import { useEffect } from "react";

import "@styles";
import "@stylesComponents/Layout.scss";

import IconSupport from "@icons/icon-support.svg";

// eslint-disable-next-line no-unused-vars
function Layout({ children, ...props }) {

  useEffect(() => {
    const script = document.createElement("script");
    script.textContent = `
      function initFreshChat() {
        window.fcWidget.init({
          token: "3af53862-17ad-408c-9c89-8b15e61e4c39",
          host: "https://wchat.freshchat.com"
        });
      }
      function initialize(i,t){
        var e;
        i.getElementById(t)
          ? initFreshChat()
          : ((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))
      }
      function initiateCall(){
        initialize(document,"Freshdesk Messaging-js-sdk");
      }
      window.addEventListener
        ? window.addEventListener("load",initiateCall,!1)
        : window.attachEvent("load",initiateCall,!1);
    `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <a
        href="http://support.fxbravo.net/"
        target="_blank"
        rel="noopener noreferrer"
        className="layout__support-link"
      >
        <img src={IconSupport} alt="Support" className="layout__support-link-icon" />
        {props.pageContext.language === "es" ? "Soporte" : "Support"}
      </a>
      {children}
    </>
  );
}

export default Layout;
